<template>
  <fieldset :read-only="readOnly">
    <div
      v-for="(group, groupIndex) in items.filter(e => e.items)"
      :key="`check-box-list-group-${groupIndex}`"
      class="bg-gray-400 border rounded mb-1"
    >
      <label class="flex items-center px-2">
        {{ group.caption || group.name }}
        &nbsp;
        <input
          type="checkbox"
          @click="onSelectAllButtonClick(group.name)"
          :checked="itemCount[group.name] == selectedCount[group.name]"
          :disabled="readOnly"
        />
      </label>
      <ul class="group">
        <li
          v-for="(item, itemIndex) in group.items"
          :key="`check-box-list-group-item-${itemIndex}`"
          :style="{ width: itemWidthPercentage }"
        >
          <div>
            <input :id="`check-box-list-group-item-checkbox-${item.value}`"
              type="checkbox"
              :value="item.value"
              v-model="selectedValues"
              @change="onChange()"
              :disabled="readOnly"
            />
            <label :for="`check-box-list-group-item-checkbox-${item.value}`">{{ item.name }}</label>
          </div>
        </li>
      </ul>
    </div>
    <ul>
      <li v-for="(item, index) in items.filter(e => e.value)" :key="`check-box-list-item-${index}`" :style="{ width: itemWidthPercentage }">
        <div>
          <input
            :id="`check-box-list-group-item-checkbox-${item.value}`"
            type="checkbox"
            :value="item.value"
            v-model="selectedValues"
            @change="onChange()"
            :disabled="readOnly"
          />
          <label :for="`check-box-list-group-item-checkbox-${item.value}`">{{ item.name }}</label>
        </div>
      </li>
    </ul>
    <label class="group">
      <input
        type="checkbox"
        @click="onSelectAllButtonClick('all')"
        :checked="itemCount['all'] == selectedCount['all']"
        :disabled="readOnly"
      />
      &nbsp;全選</label
    >
  </fieldset>
</template>

<style scoped>
fieldset {
  padding: 3px;
  border: 1px #d7d7d7 solid;
  border-radius: 4px;
  position: relative;
}
fieldset[read-only="true"] {
  border: 1px #ccc dashed;
}
ul {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
  display: table;
  table-layout: fixed;
}
ul.group {
  padding: 0;
  border-radius: 4px;
}
ul li {
  margin: 0;
  padding: 0;
  float: left;
  display: table-cell;
}
ul li div {
  margin: 2px;
}
ul li label {
  margin: 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  text-align: center;
  width: 100%;
  display: inline-block;
}
ul li label[read-only="true"] {
  border: 1px dashed #ccc;
}
ul li input[type="checkbox"] {
  opacity: 0;
  width: 1px;
  height: 1px;
  display: none;
}
ul li input[type="checkbox"]:checked ~ label {
  background: dodgerBlue;
  color: white;
}
label.group {
  margin: 3px 2px;
  padding: 5px 10px;
  border: 1px #d7d7d7 solid;
  border-radius: 4px;
  background-color: #d7d7d741;
  align-items: center;
  justify-content: center;
  font-size: smaller;
  float: right;
}
</style>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from '@cloudfun/core'
import { v1 as uuid } from 'uuid'

export default defineComponent({
  props: {
    name: String,
    modelValue: { type: Array as PropType<any[]>, default: () => [] },
    cell: Object,
    columnCount: { type: Number, default: 0 },
    readOnly: Boolean,
    items: { type: Array as PropType<any[]>, default: () => [] }
  },
  setup (props) {
    const selectedValues = ref<any[]>([])
    const itemCount = ref<any>({ all: 0 })
    const selectedCount = ref<any>({ all: 0 })

    const itemWidthPercentage = computed(() =>
      props.columnCount > 0 ? `${100 / props.columnCount}%` : 'auto'
    )

    return {
      selectedValues,
      itemCount,
      selectedCount,
      id: uuid(),
      itemWidthPercentage
    }
  },
  methods: {
    onSelectAllButtonClick (name: string) {
      const groupItem = this.items.find((e) => e.name === name)
      let items = null
      if (groupItem == null) {
        items = this.items
        if (this.selectedValues.length === this.itemCount[name]) {
          this.selectedValues = [];
        } else {
          this.selectedValues = [];
          items.forEach((item) => {
            if (item.items && Array.isArray(item.items)) {
              item.items.forEach((subItems: any) => {
                if (!this.selectedValues.includes(subItems.value)) { this.selectedValues.push(subItems.value) }
              })
            } else if (!this.selectedValues.includes(item.value)) { this.selectedValues.push(item.value) }
          })
        }
      } else {
        items = groupItem.items
        var allSelected = true
        items.forEach((item: any) => {
          allSelected &&= this.selectedValues.includes(item.value)
        })
        if (allSelected) {
          var values = groupItem.items.map((e: any) => e.value)
          this.selectedValues = this.selectedValues.filter(
            (e) => !values.includes(e)
          )
        } else {
          items.forEach((item: any) => {
            if (!this.selectedValues.includes(item.value)) { this.selectedValues.push(item.value) }
          })
        }
      }
      this.onChange()
    },
    onChange (e?: Event, silent?: boolean) {
      this.selectedCount = { all: this.selectedValues.length }
      this.selectedValues.forEach(value => {
        this.items.forEach(item => {
          if (item.items) {
            const group = item
            if (group.items.find((e: any) => e.value === value)) {
              this.selectedCount[group.name] = (this.selectedCount[group.name] || 0) + 1
            }
          }
        })
      })
      if (!silent) {
        this.$emit('update:modelValue', this.selectedValues)
        this.$emit('change', this.selectedValues)
        if (this.cell) this.cell.setValue(this.selectedValues)
      }
    }
  },
  created () {
    this.items.forEach(item => {
      if (item.items && Array.isArray(item.items)) {
        const group = item
        this.itemCount[group.name] = group.items.length
        this.itemCount.all += group.items.length
      } else this.itemCount.all++
    })
    this.selectedValues = [...this.modelValue]
    this.onChange(undefined, true)
  },
  watch: {
    items () {
      this.itemCount.all = 0
      this.items.forEach((item) => {
        if (item.items && Array.isArray(item.items)) {
          this.itemCount[item.name] = item.items.length
          this.itemCount.all += item.items.length
        } else this.itemCount.all++
      })
      this.onChange(undefined, true)
    },
    modelValue (current) {
      this.selectedValues = [...current]
      this.onChange(undefined, true)
    }
  }
})
</script>
